<template>
  <div class="login clearfix">
    <div class="login_l">
      <img class="login_l_logo" src="../assets/images/logo5.png" alt="" />
      <div class="login_l_ul">
        <div class="login_l_item">
          <div>海量视频教程</div>
          <p>不同风格字体设计视频全覆盖</p>
        </div>
        <div class="login_l_item">
          <div>作业100%点评</div>
          <p>每个视频均可提交作业</p>
        </div>
        <div class="login_l_item">
          <div>海量素材下载</div>
          <p>精选标题设计素材 设计步骤及源文件下载</p>
        </div>
        <div class="login_l_item">
          <div>素材商用无忧</div>
          <p>所有字体素材均可商用 无任何版权风险</p>
        </div>
      </div>
    </div>
    <div class="login_r" :class="loginType == 1 ? '' : 'wechat_imgs'">
      <div
        class="login_r_close"
        v-if="loginType != 6"
        @click="$parent.isLogin = false"
      >
        <img src="../assets/images/dl_icon03@2x.svg" alt="" />
      </div>
      <div
        class="login_r_type"
        v-if="loginType == 1 || loginType == 2 || loginType == 3"
      >
        <img
          @click="loginType = 3"
          v-if="loginType == 1"
          src="../assets/images/dl_zhm@2x.svg"
          alt=""
        />
        <img
          @click="loginType = 1"
          v-else
          src="../assets/images/dl_ewm@2x.svg"
          alt=""
        />
      </div>
      <div
        class="login_r_type_tip"
        v-if="loginType == 1 || loginType == 2 || loginType == 3"
      >
        <div>
          {{ loginType == 1 ? "切换为账号密码登录" : "扫码登录更安全"
          }}<img src="../assets/images/login_bj2.svg" alt="" />
        </div>
      </div>
      <!-- 微信登陆 -->
      <div class="wechat" v-if="loginType == 1">
        <div class="wechat_top">
          <!-- <img src="../assets/images/detailweixin.png" alt=""> -->
          微信扫码登录注册
        </div>
        <div class="wechat_img" id="wxcode">
          <!-- <img src="../assets/images/phone.png" alt=""> -->
        </div>
        <div class="wechat_tip">
          <!-- <div>扫码遇到问题？<span>点击这里</span></div> -->
          <!-- <div>温馨提示：微信登录后绑定手机号可实现账号互通</div> -->
        </div>
        <div class="wechat_login_more">
          <div class="wechat_more_box">
            <div class="wechat_more_one" @click="loginType = 2">
              短信验证码登录
            </div>
            <span></span>
            <div class="wechat_more_two" @click="loginType = 4">手机号注册</div>
          </div>
        </div>
      </div>
      <!-- 短信验证码登录 -->
      <div class="smsCode" v-if="loginType == 2">
        <div class="smsCode_title">短信验证码登录</div>
        <!-- <div class="smsCode_tip">未注册的手机号验证通过后将自动注册</div> -->
        <div class="smsCode_phone clearfix">
          <div>+86</div>
          <input type="text" v-model="username" placeholder="请输入手机号" />
        </div>
        <div class="smsCode_code clearfix">
          <div class="fl smsCode_code_input">
            <input type="text" v-model="code" placeholder="请输入短信验证码" />
          </div>
          <div
            class="fr smsCode_code_b"
            :class="countDown ? 'smsCode_code_active' : ''"
            @click="getCode()"
          >
            {{ countDown ? Endtime : "获取验证码" }}
          </div>
        </div>
        <div class="smsCode_login_more clearfix">
          <div class="fl" @click="loginType = 3">账号密码登录</div>
          <div class="fr" @click="loginType = 5">忘记密码</div>
        </div>
        <div class="smsCode_login_button" @click="login2Fun">登录</div>
        <div class="smsCode_wechat smsCode_wechat_login clearfix">
          <div class="fl smsCode_wechat_to" @click="checkedFun">
            <img v-if="!checked" src="../assets/images/unchecked.svg" alt="" />
            <img v-else src="../assets/images/checked.svg" alt="" />
            下次自动登录
          </div>
        </div>
      </div>
      <!-- 账号密码登录 -->
      <div class="smsCode" v-if="loginType == 3">
        <div class="smsCode_title smspassword_title">账号密码登录</div>
        <div class="smsCode_phone clearfix">
          <div>+86</div>
          <input type="number" v-model="username" placeholder="请输入手机号" />
        </div>
        <div class="smsCode_phone smspassword_phone clearfix">
          <!-- <div class="fl">密码</div> -->
          <input
            class="fr"
            type="password"
            v-model="password"
            placeholder="请输入6-30位密码"
            maxlength="30"
          />
        </div>
        <div class="smsCode_login_more clearfix">
          <div class="fl" @click="loginType = 2">手机验证码登录</div>
          <div class="fr smsCode_login_more_r">
            <div @click="loginType = 5">忘记密码</div>
            <span></span>
            <div @click="loginType = 4">注册</div>
          </div>
        </div>
        <div class="smsCode_login_button" @click="login3Fun">登录</div>
        <div class="smsCode_wechat clearfix">
          <div class="fl smsCode_wechat_to" @click="checkedFun">
            <img v-if="!checked" src="../assets/images/unchecked.svg" alt="" />
            <img v-else src="../assets/images/checked.svg" alt="" />
            下次自动登录
          </div>
          <!-- <div class="fr smsCode_req">没有账号？ <span @click="loginType = 4">立即注册</span></div> -->
        </div>
      </div>
      <!-- 注册账号 -->
      <div class="smsCode register_box" v-if="loginType == 4">
        <div class="smsCode_title smsreq_title margintop0">手机号注册</div>
        <div class="smsCode_phone clearfix">
          <div class="fl">+86</div>
          <input
            class="fr"
            type="text"
            v-model="username"
            placeholder="请输入手机号"
          />
        </div>
        <div class="smsCode_code clearfix">
          <div class="fl smsCode_code_input">
            <input type="text" v-model="code" placeholder="请输入短信验证码" />
          </div>
          <div
            class="fr smsCode_code_b"
            :class="countDown ? 'smsCode_code_active' : ''"
            @click="getCode()"
          >
            {{ countDown ? Endtime : "获取验证码" }}
          </div>
        </div>
        <div class="smsCode_phone smspassword_phone clearfix">
          <!-- <div class="fl">密码</div> -->
          <input
            class="fr"
            type="password"
            v-model="password"
            placeholder="请输入6-30位密码"
            minlength="6"
            maxlength="30"
          />
        </div>
        <div class="smsCode_phone smspassword_phone clearfix">
          <!-- <div class="fl">密码</div> -->
          <input
            class="fr"
            type="password"
            v-model="repassword"
            placeholder="确认密码"
          />
        </div>
        <div class="smsCode_login_button" @click="registerFun()">注册</div>
        <div class="smsCode_req register_login" @click="loginType = 3">
          返回登录
        </div>
      </div>
      <!-- 更改密码 -->
      <div class="smsCode change_password" v-if="loginType == 5">
        <div class="smsCode_title smsreq_title margintop0">找回密码</div>
        <div class="smsCode_phone clearfix">
          <div>+86</div>
          <input type="text" v-model="username" placeholder="请输入手机号" />
        </div>
        <div class="smsCode_code clearfix">
          <div class="fl smsCode_code_input">
            <input type="text" v-model="code" placeholder="请输入短信验证码" />
          </div>
          <div
            class="fr smsCode_code_b"
            :class="countDown ? 'smsCode_code_active' : ''"
            @click="getCode()"
          >
            {{ countDown ? Endtime : "获取验证码" }}
          </div>
        </div>
        <div class="smsCode_phone smspassword_phone clearfix">
          <!-- <div class="fl">密码</div> -->
          <input
            class="fr"
            type="password"
            v-model="password"
            placeholder="请输入6-30位密码"
            minlength="6"
            maxlength="30"
          />
        </div>
        <div class="smsCode_phone smspassword_phone clearfix">
          <!-- <div class="fl">密码</div> -->
          <input
            class="fr"
            type="password"
            v-model="repassword"
            placeholder="确认密码"
          />
        </div>
        <div
          class="smsCode_login_button change_login_button"
          @click="retrieveFun()"
        >
          确认
        </div>
        <div class="smsCode_req register_login" @click="loginType = 3">
          返回登录
        </div>
      </div>
      <!-- 绑定手机号 -->
      <div class="smsCode bind_box" v-if="loginType == 6">
        <div class="smsCode_title bind_title">登录成功，绑定手机号</div>
        <div class="bind_tip">
          根据国家法律法规要求，绑定手机号获得更高级的安全保护。同时，您可以直接使用手机号进行多平台登录，方便快捷。
        </div>
        <div class="smsCode_phone clearfix">
          <div>+86</div>
          <input type="text" v-model="username" placeholder="请输入手机号" />
        </div>
        <div class="smsCode_code clearfix">
          <div class="fl smsCode_code_input">
            <input type="text" v-model="code" placeholder="请输入短信验证码" />
          </div>
          <div class="fr smsCode_code_b" @click="getCode()">
            {{ countDown ? Endtime : "获取验证码" }}
          </div>
        </div>
        <div
          class="smsCode_login_button bind_login_button"
          @click="bindPhoneFun()"
        >
          立即绑定
        </div>
        <div class="smsCode_req bind_login" @click="quetFun">退出登录</div>
      </div>

      <div class="login_r_tip" v-if="loginType != 5 && loginType != 6">
        登录即视为同意
        <span @click="toAgreement(5)">用户服务协议</span>、
        <span @click="toAgreement(3)">隐私政策</span>和
        <span @click="toAgreement(6)">授权许可协议</span
        >。如果您成为字体江湖会员，成为会员即视为同意
        <span @click="seeShare(7)">会员服务协议</span>
      </div>
    </div>
  
  </div>
</template>

<script>
import "../assets/public/wxlogin";
export default {
  name: "login",
  data() {
    return {
      //fangshi
      loginType: 1,

      checked: false,

      username: "", //手机号
      code: "", //验证码
      password: "", //密码
      repassword: "", //确认密码

      countDown: false,
      Endtime: 60,

      wxcode: "",

      query: {},
    };
  },
  created() {
    // 在页面加载时从cookie获取登录信息
    // this.username = this.getCookie("username")
  },
  mounted() {
    // this.getUser();
    this.get_wx_qrcode();
    if (this.$route.query) {
      this.query = this.$route.query;
    }
  },
  methods: {
    // 微信登录
    get_wx_qrcode() {
      //从微信的开发文档不难看出回调域名需要经过encodeURIComponent处理
      // eslint-disable-next-line no-undef
      var obj = new WxLogin({
        self_redirect: false, //值为true时，直接在二维码的弹窗中进行页面跳转及其余操作,
        id: "wxcode", //需要承载二维码的容器id
        appid: "wxa63eee7ec16a8c12",
        scope: "snsapi_login", //网页授权，目前网页只有这一种方式，静默授权
        redirect_uri: encodeURIComponent("https://www.zitisheji.com/#/index"), //回调域名(地址前部分是项目域名，后部分loginSuccess是微信需要跳转的路径（vue中的路由名称）)
        state: Math.random(),
        style: "block",
        href: "https://backend.zitisheji.com/static/index/css/qrcode.css",
      });
    },
    // 验证码登录
    login2Fun() {
      var that = this;
      var params = {
        username: this.username,
        code: this.code,
        type_login: 1,
      };
      this.$api.POST(this.$face.loginCode, params, function (res) {
        localStorage.setItem("access_token", res.data.access_token);
        that.$parent.isLogin = false;
        localStorage.removeItem("userId");
        that.$parent.getUser();
        that.$parent.listFun();
        that.$router.push({
          name: "refresh",
          query: this.query,
        });
      });
    },
    // 手机密码登录
    login3Fun() {
      var that = this;
      var params = {
        username: this.username,
        password: this.password,
        type_login: 1,
      };
      this.$api.POST(this.$face.login, params, function (res) {
        if (that.checked) {
          that.setUserInfo();
        }
        localStorage.setItem("access_token", res.data.access_token);
        that.$parent.isLogin = false;
        localStorage.removeItem("userId");
        that.$parent.getUser();
        that.$parent.listFun();
        that.$router.push({
          name: "refresh",
          query: this.query,
        });
      });
    },

    // 注册
    registerFun() {
      var that = this;
      if(this.password.length<6){
        that.$utile.prompt("error", "密码至少输入6位");
        return false;
      }
      var params = {
        mobile: this.username,
        password: this.password,
        repassword: this.repassword,
        code: this.code,
        source:localStorage.getItem("source")
      };
      this.$api.POST(this.$face.loginRegister, params, function (res) {
        that.$utile.prompt("success", "注册成功，请登录");
        that.loginType = 3;
      });
    },

    // 找回密码
    retrieveFun() {
      var that = this;
       if(this.password.length<6){
        that.$utile.prompt("error", "密码至少输入6位");
      }
      var params = {
        username: this.username,
        password: this.password,
        repassword: this.repassword,
        code: this.code,
      };
      this.$api.POST(this.$face.loginRetrieve, params, function (res) {
        that.$utile.prompt("success", "找回密码成功，请登录");
        that.loginType = 3;
      });
    },

    // 绑定手机号
    bindPhoneFun() {
      var that = this;
      var params = {
        mobile: this.username,
        code: this.code,
      };
      this.$api.POST("member/mobilebind", params, function (res) {
        that.$utile.prompt("success", "绑定手机号成功");
        that.$parent.isLogin = false;
        that.$parent.getUser();
        that.$parent.listFun();
        that.$router.push({
          name: "refresh",
          query: this.query,
        });
      });
    },

    // 获取验证码
    getCode() {
      var that = this;
      var params = {
        mobile: this.username,
      };
      this.$api.POST(this.$face.loginSms, params, function (res) {
        that.countdownFun();
        that.countDown = true;
      });
    },
    // 验证码倒计时
    countdownFun() {
      setTimeout(() => {
        this.Endtime--;
        if (this.Endtime == 0) {
          this.countDown = false;
          this.Endtime = 60;
        } else {
          this.countdownFun();
        }
      }, 1000);
    },
    wxLoginFun() {
      var that = this;
      var params = {
        code: this.wxcode,
        source:localStorage.getItem("source")
      };
      this.$api.POST(this.$face.authWechatopenredirect, params, function (res) {
        localStorage.setItem("access_token", res.data.access_token);

        localStorage.removeItem("userId");
        that.$parent.getUser();
        that.$parent.listFun();
        // if (res.data.is_mobile != 1) {
        // 	that.loginType = 6;
        // } else {
        that.$parent.isLogin = false;
        that.$router.push({
          name: "refresh",
          query: this.query,
        });
        //}
      });
    },

    // 协议
    seeShare(id) {
      let routeUrl = this.$router.resolve({
        path: "/agreement",
        query: {
          id: id,
        },
      });
      window.open(routeUrl.href, "_blank");
    },

    // 协议新
    toAgreement(id) {
      this.$parent.isLogin = false;
      this.$router.push({
        name: "userAgreement",
        query: {
          id: id,
        },
      });
    },

    checkedFun() {
      this.checked = !this.checked;
      localStorage.setItem("checked", this.checked);
      if (this.checked == false) {
        this.setCookie("username", "");
        this.setCookie("password", "");
      }
    },

    // 储存表单信息
    setUserInfo: function () {
      // 向cookie中储存登录信息

      this.setCookie("username", this.username);
      this.setCookie("password", this.password);
    },
    // 获取cookie
    getCookie: function (key) {
      if (document.cookie.length > 0) {
        var start = document.cookie.indexOf(key + "=");
        if (start !== -1) {
          start = start + key.length + 1;
          var end = document.cookie.indexOf(";", start);
          if (end === -1) end = document.cookie.length;
          console.log(document.cookie.substring(start, end));
          return unescape(document.cookie.substring(start, end));
        }
      }
      return "";
    },
    // 保存cookie
    setCookie: function (cName, value, expiredays) {
      var exdate = new Date();
      exdate.setDate(exdate.getDate() + expiredays);
      document.cookie =
        cName +
        "=" +
        decodeURIComponent(value) +
        (expiredays == null ? "" : ";expires=" + exdate.toGMTString());
      console.log(document.cookie);
    },

    // 退出登录
    quetFun() {
      localStorage.removeItem("access_token");
      localStorage.removeItem("userInfo");
      this.$store.commit("userInfo", "");
      this.$parent.userShow = false;
      this.$router.push({
        name: "/",
      });
    },
  },
  watch: {
    loginType() {
      console.log(this.loginType);
      if (this.loginType == 3) {
        this.$set(
          this,
          "checked",
          localStorage.getItem("checked") == "true" ? true : false || false
        );
        // this.checked = localStorage.getItem('checked') || false;
        if (this.getCookie("username") != "") {
          this.username = this.getCookie("username");
          this.password = this.getCookie("password");
        }

        console.log(this.checked);
        this.$forceUpdate;
      }
    },
    "$route.query": {
      handler(newVal, oldVal) {
        console.info(newVal, oldVal);
        this.wxcode = this.$route.query["code"];
        console.log("wxcode", this.wxcode);
        if (this.wxcode) {
          this.wxLoginFun();
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped>
.login {
  position: absolute;
  background-color: #ffffff;
  width: 740px;
  height: 484px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  display: flex;
}

.login_l {
  width: 285px;
  height: 100%;
  background: url(../assets/images/dl_bg@2x.png) no-repeat;
  background-position: left top;
  background-size: 100% auto;
  padding: 28px 0 0 30px;
  box-sizing: border-box;
  border-radius: 10px 0 0 10px;
  overflow: hidden;
}

.login_l_logo {
  width: auto;
  height: 35px;
  margin-bottom: 110px;
}

.login_l_item {
  padding-left: 22px;
  box-sizing: border-box;
  background: url(../assets/images/dl_icon01@2x.png) no-repeat;
  background-size: 16px 10px;
  background-position: left 4px;
  color: #ffffff;
  margin-bottom: 32px;
}

.login_l_item div {
  font-size: 15px;
  line-height: 16px;
  margin-bottom: 11px;
}

.login_l_item p {
  font-size: 11px;
  opacity: 0.8;
}

.login_r {
  width: 455px;
  height: 100%;
  position: relative;
}

.login_r_close {
  width: 31px;
  height: 31px;
  position: absolute;
  top: 0px;
  right: -46px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}

.login_r_close img {
  width: 31px;
  height: 31px;
  cursor: pointer;
}

.login_r_tip {
  width: 100%;
  background-color: #f9fafb;
  position: absolute;
  left: 0;
  bottom: 0;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 18px;
  letter-spacing: 0px;
  color: #999999;
  padding-left: 24px;
  padding-right: 42px;
  padding-top: 11px;
  padding-bottom: 15px;
  box-sizing: border-box;
  border-radius: 0 0 10px 0;
  overflow: hidden;
}

.login_r_tip span {
  color: #666;
  cursor: pointer;
}

.login_r_tip span:hover {
  color: #5857ff;
}

/* 微信登陆 */
.wechat {
  padding-top: 64px;
}

.wechat_top {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  letter-spacing: 1px;
  color: #333333;
  margin-bottom: 48px;
  margin-top: 21px;
}

.wechat_top img {
  width: 26px;
  height: 26px;
  margin-right: 11px;
}

.wechat_img {
  width: 170px;
  height: 170px;
  margin: 0 auto 16px;
}

.wechat_img >>> iframe {
  width: 170px !important;
  height: 170px !important;
}

.wechat_imgs >>> iframe {
  display: none !important;
}

.wechat_img >>> .impowerBox .qrcode {
  margin-top: 0;
}

.wechat_tip {
  text-align: center;
  font-size: 12px;
  line-height: 1.4;
  color: #999999;
}

.wechat_tip span {
  color: #5857ff;
  cursor: pointer;
}

.wechat_login_more {
  width: 100%;
  padding: 0 22px 0 27px;
  box-sizing: border-box;
}

.wechat_more_box {
  /* border-top: dashed 1px #cccccc; */
  margin-top: 4px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  letter-spacing: 1px;
  color: #333333;
}

.wechat_more_box span {
  display: block;
  width: 2px;
  height: 11px;
  background-color: #e0e1e1;
}

.wechat_more_one {
  background: url(../assets/images/dl_icond@2x.svg) no-repeat;
  background-position: left center;
  background-size: 16px auto;
  margin-right: 26px;
  padding-left: 24px;
  height: 22px;
  line-height: 22px;
  cursor: pointer;
  font-weight: bold;
  color: #44403f;
}

.wechat_more_two {
  padding-left: 21px;
  background: url(../assets/images/dl_iconsj@2x.svg) no-repeat;
  background-position: left center;
  background-size: 14px auto;
  margin-left: 33px;
  height: 22px;
  line-height: 22px;
  cursor: pointer;
  font-weight: bold;
  color: #44403f;
}

/* 短信验证码 */
.smsCode {
  padding-top: 65px;
  padding-left: 61px;
  padding-right: 61px;
  box-sizing: border-box;
}

.smsCode_title {
  font-size: 20px;
  line-height: 1;
  font-weight: bold;
  font-stretch: normal;
  letter-spacing: 1px;
  color: #333333;
  text-align: center;
  margin-bottom: 45px;
  margin-top: 15px;
}

.smspassword_title {
  margin-bottom: 45px;
  margin-top: 15px;
}

.smsreq_title {
  margin-bottom: 32px;
}

.smsCode_tip {
  font-size: 12px;
  color: #d5d6d6;
  line-height: 1;
  text-align: center;
  margin-bottom: 40px;
}

.smsCode_phone {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  border: solid 1px #f0f1f1;
  box-sizing: border-box;
  margin-bottom: 26px;
  position: relative;
}

.smsCode_phone div {
  position: absolute;
  top: 0;
  left: 0;
  width: 72px;
  line-height: 38px;
  text-align: left;
  padding-left: 30px;
  box-sizing: border-box;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #646262;
}

.smsCode_phone input {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding-left: 72px;
  box-sizing: border-box;
  font-size: 13px;
  color: #646262;
}

.smspassword_phone input {
  padding-left: 30px;
  color: #646262;
}

.smsCode_phone input:focus,
.smsCode_code_input input:focus {
  outline: 1px solid #5857ff;
  color: #646262;
}

.smsCode_phone input::placeholder,
.smsCode_code_input input::placeholder {
  color: #b7b7b7;
}

.smsCode_code {
  margin-bottom: 30px;
}

.smsCode_code_input {
  width: 210px;
  height: 40px;
  border-radius: 4px;
  border: solid 1px #f0f1f1;
  box-sizing: border-box;
}

.smsCode_code_input input {
  width: 100%;
  padding: 0 12px 0 30px;
  line-height: 38px;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 13px;
  color: #333333;
}

.smsCode_code_b {
  width: 115px;
  height: 40px;
  line-height: 38px;
  background-color: #5857ff;
  border-radius: 4px;
  text-align: center;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #ffffff;
  cursor: pointer;
  border: solid 1px #5857ff;
  box-sizing: border-box;
}

.smsCode_code_active {
  background-color: #f5f5f5;
  border: solid 1px #f0f1f1;
  color: #acadad;
}

.smsCode_login_more {
  margin-bottom: 26px;
  font-size: 14px;
  line-height: 14px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
}

.smsCode_login_more > div:nth-child(1) {
  color: #6f6eff;
  cursor: pointer;
}

.smsCode_login_more > div:nth-child(2) {
  color: #b0b1b1;
  cursor: pointer;
}

.smsCode_login_more_r {
  display: flex;
  align-items: center;
}

.smsCode_login_more_r span {
  display: block;
  width: 1px;
  height: 11px;
  background-color: #c9c9c9;
  margin: 0 10px;
}

.smsCode_login_button {
  width: 100%;
  height: 40px;
  line-height: 40px;
  background-color: #5857ff;
  border-radius: 4px;
  text-align: center;
  font-size: 19px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 1px;
  color: #ffffff;
  margin-bottom: 15px;
  cursor: pointer;
}

.smsCode_wechat_to {
  height: 18px;
  line-height: 18px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #abacac;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.smsCode_wechat_to img {
  width: 16px;
  height: 16px;
  margin-right: 6px;
}

.smsCode_req {
  font-size: 14px;
  line-height: 18px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #999999;
}

.smsCode_req span {
  color: #fcc808;
  cursor: pointer;
}

/* 账号密码 */
.passwordLoginType {
  margin-top: 28px;
  position: relative;
  border-top: 1px solid #eeeeee;
  margin-bottom: 33px;
}

.passwordLoginType span {
  width: 78px;
  height: 14px;
  display: block;
  position: absolute;
  left: 50%;
  margin-left: -39px;
  background: #ffffff;
  top: -8px;
  text-align: center;
  color: #999999;
}

.passwordLoginwx {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* 注册 */
.register_box {
  padding-top: 54px;
}

.register_box .smsCode_code,
.register_box .smsCode_phone {
  margin-bottom: 18px;
}

.register_login {
  text-align: center;
  font-size: 15px;
  line-height: 1;
  color: #6f6eff;
  cursor: pointer;
}

/* 更改密码 */
.change_password .smsCode_code,
.change_password .smsCode_phone {
  margin-bottom: 25px;
}

.change_login_button {
  margin-bottom: 22px;
}

.change_login_back {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.change_login_back img {
  margin-right: 5px;
}

/* 绑定手机号 */
.bind_box .smsCode_code,
.bind_box .smsCode_phone {
  margin-bottom: 24px;
}

.bind_title {
  text-align: left;
  margin-bottom: 26px;
}

.bind_tip {
  font-size: 14px;
  color: #889099;
  line-height: 1.4;
  margin-bottom: 40px;
}

.bind_login_button {
  margin-bottom: 35px;
}

.bind_login {
  color: #bababb;
  font-size: 16px;
  text-align: center;
}

/* 右上角切换 */
.login_r_type {
  position: absolute;
  top: 0;
  right: 0;
  width: 79px;
  height: 79px;
  background: url(../assets/images/login_bj1.png) no-repeat;
  background-size: 79px 79px;
  background-position: right top;
  /* padding: 8px 8px 0 0; */
  box-sizing: border-box;
}

.login_r_type img {
  width: 56px;
  height: auto;
  margin-top: 16px;
  margin-left: 6px;
  cursor: pointer;
}

.login_r_type_tip {
  position: absolute;
  top: 18px;
  right: 68px;
  padding: 0 12px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  background-color: #e6efff;
  font-size: 12px;
  color: #6666ff;
  border-radius: 4px;
}

.login_r_type_tip > div {
  position: relative;
}

.login_r_type_tip > div img {
  position: absolute;
  right: -20px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 12px;
  height: 18px;
}
</style>

<style>
.el-checkbox {
  color: #999999 !important;
}

.el-checkbox__inner:hover {
  border-color: #fcc808 !important;
}

.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #fcc808 !important;
  border-color: #fcc808 !important;
}

.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #999999 !important;
}
.margintop0{ margin-top: -15px !important;} 
</style>
